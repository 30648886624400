import '../scss/main.scss';

var Cookies = require('./lib/js.cookie.js');

$(function () {
    //przełączanie okienek głównego ekranu
    $(document).on('click', '#right-content > .nav > .nav-item', function () {
        var index = $('#right-content .nav > .nav-item').index(this);
        Cookies.set('mainScreen_activeTab', index, {expires: 9999});
    });

    $('[data-fancybox]').fancybox({
        buttons: [
            'slideShow',
            'fullScreen',
            'thumbs',
            'zoom',
            'close'
        ],
    })
});